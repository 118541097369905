<template>
    <div id="app">
      <div class="main_bg"></div>
      <v-card
          class="ma-0 pa-0"
          color="#ffffff"
          dark>
          
          <v-app-bar
          app
          :fixed="true"
          color="#ffffff"
          rounded="0"
          dark
          dense>
              <v-row justify="center">
                  <v-btn class="mt-1 mr-1" icon to="/home" color="primary" height="30" width="30"><v-icon>mdi-home</v-icon></v-btn>
                  <v-spacer></v-spacer>
                  <v-img
                      :src="require('@/assets/logo3.png')"
                      contain
                      height="40"
                      max-width="200"
                  />
                  <v-spacer></v-spacer>
                  <div class="lang_cursor mt-1 mr-1" v-if="this.$i18n.locale=='en'">
                    <v-img 
                      contain
                      :src="require('@/assets/th.png')"
                      height="30"
                      width="30"
                      @click="langThaiClick"
                    />
                  </div>
                  <div class="lang_cursor mt-1 mr-1" v-else>
                    <v-img 
                      contain
                      :src="require('@/assets/en.png')"
                      height="30"
                      width="30"
                      @click="langEngClick"
                    />
                  </div>
              </v-row>
          </v-app-bar>
        </v-card>
  
      <v-container v-if="loading">
        <template>
          <v-row
            width="100%"
            class="fill-height ma-0 pa-0"
            justify="center">
            <v-progress-circular
              indeterminate
              color="#FFD600"
              width="6"
              size="64"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-container>
  
      <v-container fluid class="ma-0 pa-0" v-else>
        <v-layout justify-center>
            <div :class="this.$i18n.locale=='en' ? 'redeem_bg_en' : 'redeem_bg_th'"></div>

            <div class="button_echomatcher lang_cursor">
              <v-img 
                contain
                :src="require(this.$i18n.locale=='en' ? '@/assets/redeem/ecomatcher/redeem_form2_en.png': '@/assets/redeem/ecomatcher/redeem_form2_th.png')"
                height="100"
                max-width="370"
                @click="showConfirm">
              </v-img>
            </div>
            <div class="label_tmcode">{{ userInfo?.tm_code ?? "" }}</div>
            <div class="label_tmname" v-if="show_input_tm_name === false">{{ tm_name }}</div>
            <div class="label_phone">{{ userInfo?.Mobile1 ?? "" }}</div>
            <div class="label_email" v-if="show_input_email === false">{{ userInfo?.tm_email ?? "" }}</div>
            <div class="label_pointremain">{{ numberFormat(userInfo?.mp_point ?? 0, 0) }}</div>
            <div class="label_pointused">{{ numberFormat(point_used ?? 0, 0) }}</div>
            <!-- <div class="label_pointused">{{ show_input_tm_name }}</div> -->
            <div class="input_tm_name" v-if="show_input_tm_name === true">
              <v-text-field
                class="text_input"
                v-model="tm_name"
                type="text"
                autocomplete="tm_name"
                dense
                hide-details
                height="20"
              ></v-text-field>
            </div>
            <div class="input_email" v-if="show_input_email === true">
              <v-text-field
                class="text_input"
                v-model="email"
                type="text"
                autocomplete="email"
                dense
                hide-details
                height="20"
              ></v-text-field>
            </div>

            <div class="input_number_of_trees">
              <v-text-field
                class="text_input_no_of_trees inputNumber"
                v-model="number_of_trees"
                type="number"
                autocomplete="number_of_trees"
                dense
                hide-details
                height="20"
              ></v-text-field>
            </div>
        </v-layout>
      </v-container>

      <template>
        <div class="ma-0 pa-0">
            <v-dialog
              v-model="isShowConfirm"
              width="320px"
              max-width="320px">

              <div>
                <v-img 
                  contain
                  :src="require(this.$i18n.locale=='en' ? '@/assets/redeem/ecomatcher/confirm1_en.jpg': '@/assets/redeem/ecomatcher/confirm1_th.jpg')"
                  width="320px"
                  max-width="320px">
                </v-img>
              </div>

              <div class="label_email2">{{ email }}</div>
              <div class="label_no_of_tree">{{ number_of_trees }}</div>
              <div class="label_no_of_point">{{ numberFormat(point_used ?? 0, 0) }}</div>

              <div class="input_otp">
                <v-text-field
                  class="text_input_otp inputNumber"
                  v-model="otp"
                  type="number"
                  autocomplete="otp"
                  dense
                  hide-details
                  height="20"
                ></v-text-field>
              </div>

              <div class="button_confirm2 lang_cursor">
                <v-img 
                  contain
                  :src="require(this.$i18n.locale=='en' ? '@/assets/redeem/ecomatcher/confirm2_en.png': '@/assets/redeem/ecomatcher/confirm2_th.png')"
                  height="40"
                  max-width="150"
                  @click="confirmEcomatcher">
                </v-img>
              </div>
              <div class="button_confirm3 lang_cursor">
                <v-img 
                  contain
                  :src="require(this.$i18n.locale=='en' ? '@/assets/redeem/ecomatcher/confirm3_en.png': '@/assets/redeem/ecomatcher/confirm3_th.png')"
                  height="30"
                  max-width="100"
                  @click="requestOtp">
                </v-img>
              </div>
            
            </v-dialog>
        </div>
      </template>

      <ShowOverlay
        v-model="isShowOverlay">
      </ShowOverlay>
      <ShowMessage 
        v-model="isShowMessage" 
        :msg="showmessage_msg"
        :width="showmessage_width"
        :callBack="showmessage_callback">
      </ShowMessage>
    </div>
  </template>
  
  <script>
  import { server } from "@/services/constants";
  import ShowOverlay from "@/components/ShowOverlay"
  import ShowMessage from "@/components/ShowMessage"
  import api from "@/services/api_villa";

  export default {
    name: "RedeemEcomatcherRedeem",
    data() {
      return {
        loading: false,
        isShowConfirm: false,
        userInfo: null,

        otp: "",
        tm_name: "",
        email: "",
        number_of_trees: 1,

        isShowOverlay: false,
        isShowMessage: false,
        showmessage_msg: "",
        showmessage_width: 400,
        showmessage_callback: null
      };
    },
    components: {
      ShowOverlay,
      ShowMessage
    },
    async mounted() {
      try {
        this.loading = true;
        // await api.refreshUserInfo(this.phoneNo);
        this.showmessage_callback = null;
        this.userInfo = await api.getMember(this.docno, this.refno);
        if (this.userInfo == null || this.tmcode.trim() == "") {
          this.showmessage_callback = this.onShowMessageCallBack;
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_member}`, 400);
          return;
        }
        this.tm_name = (this.tmname ?? "").toString().trim();
        this.email = (this.userInfo?.tm_email ?? "").toString().trim();
      } catch (error) {
        this.showMessage(error.toString(), 400);
      }
      finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    methods: {
      showMessage(showmessage_msg, showmessage_width) {
        this.showmessage_msg = showmessage_msg;
        this.showmessage_width = showmessage_width;
        this.isShowMessage = true;
      },
      onShowMessageCallBack() {
        this.$router.replace("/redeem/ecomatcher", () => {});
      },
      langThaiClick() {
        this.$i18n.locale = 'th';
        localStorage.setItem('lang', 'th');
      },
      langEngClick() {
        this.$i18n.locale = 'en';
        localStorage.setItem('lang', 'en');
      },
      async requestOtp() {
        try {
          this.showmessage_callback = null;
          this.isShowOverlay = true;
          await api.getOtp(this.userInfo.Mobile1.toString(),'');
        } catch (error) {
          this.showMessage(error.toString(), 400);
        }
        finally {
          setTimeout(() => {
            this.isShowOverlay = false;
          }, 1000);
        }
      },
      async showConfirm() {
        try {
          this.showmessage_callback = null;

          // if (this.tm_name.trim() == "") {
          //   this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_tm_name}`, 400);
          //   return;
          // }

          if (this.email.trim() == "") {
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_email}`, 400);
            return;
          }

          if (this.number_of_trees == "" || parseInt(this.number_of_trees ?? 0) <= 0) {
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_number_of_trees}`, 400);
            return;
          }

          if (parseInt(this.userInfo?.mp_point ?? 0) < parseInt(this.point_used ?? 0)) {
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_point}`, 400);
            return;
          }

          await this.requestOtp();
          this.isShowConfirm = true;
        } catch (error) {
          this.showMessage(error.toString(), 400);
        }
      },
      numberFormat(value, decimal) {
        return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
      },
      async confirmEcomatcher() {
        try {
          this.showmessage_callback = null;

          if (this.otp.trim() == "") {
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_otp}`, 400);
            return;
          }

          this.isShowOverlay = true;

          let result = await api.checkOtp(this.userInfo.Mobile1.toString(), this.otp.toString());
          if (!result) {
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_otp}`, 400);
            return;
          }

          //** test **/
          // result = await api.test_redeem_trees(this.tm_name, this.email);

          //** production **/
          result = await api.redeem_trees(this.userInfo.tm_code, this.tm_name, this.email, parseInt(this.number_of_trees), parseInt(this.point_used), this.docno, this.refno);
          
          if (result) {
            // await api.refreshUserInfo(this.phoneNo);
            // await api.getMember(this.docno, this.refno);
            this.showmessage_callback = this.onShowMessageCallBack;
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.redeem_success}`, 400);
            this.isShowConfirm = false;
            // this.$router.replace("/home", () => {});
            // this.$router.replace("/redeem/ecomatcher", () => {});
          }
          else
            this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.redeem_not_success}`, 400);
        } catch (error) {
          this.showMessage(error.toString(), 400);
        }
        finally {
          setTimeout(() => {
            this.isShowOverlay = false;
          }, 1000);
        }
      },
    },
    computed: {
      // userInfo() {
      //   return JSON.parse(localStorage.getItem('userInfo') ?? {});
      // },
      phoneNo() {
        return localStorage.getItem(server.TOKEN_KEY);
      },
      show_input_email() {
        return true;
        //return ((this.userInfo == null) || (this.userInfo?.tm_email == null) || (this.userInfo?.tm_email?.toString().trim().length == 0));
      },
      point_used() {
        let n = this.number_of_trees;
        if (!n)
          n = 0;
        return parseInt(n) * 800;
      },
      tmcode() {
        return `${this.userInfo?.tm_code ?? ""}`;
      },
      tmname() {
        return `${this.userInfo?.tm_firstname ?? ""} ${this.userInfo?.tm_lastname ?? ""} `;
      },
      show_input_tm_name() {
        return ((this.tmname == null) || (this.tmname.toString().trim().length == 0));
      },
      docno() {
        return this.$route.params.docno ?? "";
      },
      refno() {
        return this.$route.params.refno ?? "";
      }
    }
  }
  </script>
  
  <style scoped>
    .main_bg {
      margin: 0px;
      padding: 0px;
      height: 100%;
      width: 100%;
      color: #ffffff;
      background: linear-gradient(#1b5e20, #e8f5e9);
      /* background: linear-gradient(#e8f5e9, #1b5e20); */
      background-size:100% 100%;
      background-repeat: no-repeat;
      position: fixed;
    }

    /* .confirm_bg_en {
        background-image: url("../../../assets/redeem/ecomatcher/confirm1_en.jpg");
        width: 350px;
        max-width: 350px;
        margin: 0;
        padding: 0;
        background-size:100% 100%;
        background-repeat: no-repeat;
        position: relative;
    }
    .confirm_bg_th {
        background-image: url("../../../assets/redeem/ecomatcher/confirm1_th.jpg");
        width: 350px;
        max-width: 350px;
        margin: 0;
        padding: 0;
        background-size:100% 100%;
        background-repeat: no-repeat;
        position: relative;
    } */
    .button_confirm2 {
      /* background-color: red; */
      margin-left: 40px;
      margin-top: -47px;
      max-width: 130px;
      position: fixed;
    }
    .button_confirm3 {
      /* background-color: red; */
      margin-left: 225px;
      margin-top: -104px;
      max-width: 80px;
      position: absolute;
    }

    .redeem_bg_en {
        background-image: url("../../../assets/redeem/ecomatcher/redeem_form1_en.jpg");
        width: 370px;
        height: 500px;
        max-width: 370px;
        margin: 0;
        padding: 0;
        background-size:100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        /* left:0;right:0;top:0;bottom:0; */
    }
    .redeem_bg_th {
      background-image: url("../../../assets/redeem/ecomatcher/redeem_form1_th.jpg");
        width: 370px;
        height: 500px;
        max-width: 370px;
        margin: 0;
        padding: 0;
        background-size:100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        /* left:0;right:0;top:0;bottom:0; */
    }
    .lang_cursor {
      cursor: pointer;
    }

    .button_echomatcher {
      margin-left: 8px;
      margin-top: 400px;
      width: 350px;
      position: absolute;
    }
    .input_tm_name {
      margin-left: 80px;
      margin-top: 190px;
      width: 160px;
      text-align: left;
      border: none;
      position: absolute;
    }
    .input_email {
      margin-left: 80px;
      margin-top: 250px;
      width: 160px;
      text-align: left;
      border: none;
      position: absolute;
    }
    .input_number_of_trees {
      margin-left: 70px;
      margin-top: 353px;
      font-size: 1.6rem !important;
      width: 100px;
      /* text-align: center; */
      border: none;
      position: absolute;
    }
    .input_otp {
      margin-left: 120px;
      margin-top: -98px;
      width: 80px;
      text-align: left;
      border: none;
      position: absolute;
    }

    .label_tmcode {
      text-align: left;
      margin-left: -10px;
      margin-top: 157px;
      font-size: 1.2rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .label_tmname {
      text-align: left;
      margin-left: 60px;
      margin-top: 190px;
      font-size: 0.8rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .label_phone {
      text-align: left;
      margin-left: 40px;
      margin-top: 216px;
      font-size: 1.2rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .label_email {
      text-align: left;
      margin-left: 70px;
      margin-top: 250px;
      font-size: 0.8rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .label_pointremain {
      /* background-color: red; */
      width: 120px;
      text-align: center;
      margin-left: -70px;
      margin-top: 285px;
      font-size: 2.15rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .label_pointused {
      /* background-color: red; */
      width: 180px;
      text-align: left;
      margin-left: 150px;
      margin-top: 374px;
      font-size: 1.5rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .label_email2 {
      text-align: left;
      margin-left: 112px;
      margin-top: -175px;
      font-size: 0.8rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .label_no_of_point {
      text-align: left;
      margin-left: 120px;
      margin-top: -128px;
      font-size: 1.2rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }

    .text_input {
      margin: 0px;
      padding: 0px;
      background-color: #ffffff;
      color: #000000;
      /* position: relative; */
    }

    .label_no_of_tree {
      text-align: left;
      margin-left: 120px;
      margin-top: -155px;
      font-size: 1.2rem !important;
      font-weight: bold;
      position: absolute;
      color: #1b5e20;
    }
    .text_input_no_of_trees {
      margin: 0px;
      padding: 0px;
      background-color: #ffffff;
      color: #000000;
      font-size: 1.5rem !important;
      font-weight: bold;
      /* position: relative; */
    }
    .text_input_otp {
      margin: 0px;
      padding: 0px;
      background-color: #ffffff;
      color: #000000;
      font-size: 1rem !important;
      font-weight: bold;
      /* position: relative; */
    }
    .inputNumber >>> input[type='number'] {
      -moz-appearance:textfield;
    }
    .inputNumber >>> input::-webkit-outer-spin-button,
    .inputNumber >>> input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  </style>